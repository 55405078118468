export const dataProcess = [
  {
    accHeader: "Adquisición de información",
    accBody:"Adquisición de información de la empresa a través de un cuestionario con el objetivo de conocer sobre su filosofía, entorno, misión, visión, publico objetivo, productos y otros datos relevantes.",
  },
  {
    accHeader: "Análisis",
    accBody:"Analisis de la información obtenida, busqueda de puntos comunes y diferenciadores con respecto a la competencia, sitentización de los valores y objetivos de la empresa.",
  },
  {
    accHeader: "Desarrollo de líneas de diseño",
    accBody:"Desarrollo de diversos conceptos que simbolicen a la marca y bocetación de los mismos.",
  },
  {
    accHeader: "Definición",
    accBody: "Elección de linea de diseño, ajustes y vectorización final.",
  },
  {
    accHeader: "Paleta de color y tipografía",
    accBody:"Elección definiva de paleta de color para formatos digitales y físicos. Elección de la tipografía del logotipo y el eslogan.",
  },
  {
    accHeader: "Versiones",
    accBody:"Creación de versiones de la marca en distintos formatos y sobre distintos fondos.",
  },
  {
    accHeader: "Complementos",
    accBody:"Diseño de elementos complementarios de la marca. Elección de tipografías para utilización en la papelería, web y resto de elementos pertenecientes a la marca. ",
  },
  {
    accHeader: "Manual de marca",
    accBody:"Creación de manual con las versiones de la marca gráfica original, positivo, negativo, continuo, horizontal, vertical, mínimo, convivencia, grilla, usos incorrectos, paleta de color, tipografía, elementos complementarios y otros.",
  },
  {
    accHeader: "Papelería y redes sociales",
    accBody:"Diseño de papelería, plantilla para redes sociales y otros items aplicando la marca gráfica y los elementos complementarios de la identidad previamente realizados.",
  },
]

export const dataProcess2 = [
  {
    accHeader: "Arquitectura de la información de la web",
    accBody:"Clasificiación, jerarquización y etiquetación de la información de la web.",
  },
  {
    accHeader: "Creación de mapa de navegación",
    accBody:"Creción de nueva mapa de navegación (navbar, sidebar, etc) teniendo en cuenta la información obtenida.",
  },
  {
    accHeader: "Diseño de wireframe",
    accBody:"Bocetación de nueva interfaz para análisis preeliminar de la nueva navegación, jerarquía y organización de elementos. ",
  },
  {
    accHeader: "Prototipado y testeo",
    accBody: "Prueba inicial de nueva navegación para ajustar detalles.",
  },
  {
    accHeader: "Diseño de elementos",
    accBody: "Creación de paleta de tipografías, colores, diseño de iconos, banners y otras imágenes",
  },
  {
    accHeader: "Diseño de Interfaz",
    accBody:"Diseño de interfaz utilizando los elementos previamente diseñados en base al wireframe previamente realizado.",
  },
  {
    accHeader: "Programación de servicios",
    accBody:"Diseño de prototipado completo para posterior testeo de usuarios.",
  },
  {
    accHeader: "Maquetado web",
    accBody:"Diseño de prototipado completo para posterior testeo de usuarios.",
  },
  {
    accHeader: "Deployment",
    accBody:"Exportación de archivos y subida al servidor",
  },
]

export const dataItem = [
  {
    itemTitle: "Estudio gráfico de mercado",
    itemDesc:"Estudio de las marcas gráficas de la competencia, analisis de los colores, tipología y elementos usados.",
  },
  {
    itemTitle: "Bocetos",
    itemDesc:"Realización de bocetos con primeras ideas y conceptos para representar a la marca.",
  },
  {
    itemTitle: "Líneas de diseño",
    itemDesc:"Elección de 3 líneas de diseño de las realizadas en los bocetos, vectorización y pruebas.",
  },
  {
    itemTitle: "Definición y ajustes",
    itemDesc:"Elección de línea de diseño y ajustes. Creación de marca gráfica definitiva.",
  },
  {
    itemTitle: "Concepto símbolo",
    itemDesc: "Símbolo creado a partir de la inicial del nombre, la cual se realiza con dos dos flechas cruzándose en referencia al intercambio.",
  },
  {
    itemTitle: "Logosímbolo",
    itemDesc:"Logosímbolo creado con base en la tipografía redondeada Quicksans Bold, a la cual se remplazó su inicial por el símbolo de la marca.",
  },
  {
    itemTitle: "Elementos complementarios",
    itemDesc:"Como elementos complementarios de la marca se dedició utilizar la tipografía Ubunto para el texto, iconos líneales hechos con varios trazos geométricos, puntas de flechas, líneas de contorno incompletas con terminaciones redondeadas, formas geométricas y angulosas y colores azules y grises.  ",
  },
  {
    itemTitle: "Color",
    itemDesc:"Los colores utilizados para la marca son azules del tono del color de la marca gráfica con variaciones en el brillo, el negro y distitos grises para dar contraste. ",
  },
  {
    itemTitle: "Tipografía",
    itemDesc:"La tipografía escogida para comunicaciones de la marca fue Ubuntu. Desarrollada para usar en medios digitales, y con una gran legibilidad y flexibilidad, util tanto para titulos como para grandes bloques de texto. Esta tipografía conplemente bien la tipografía utilizada para el logosimbolo, contrastando con trazos mas uniformes y de terminación recta, pero manteniendo una estetica moderna. La familia de fuentes Ubuntu forma parte de un conjunto de fuentes libres/abiertas desarrolladas durante 2010-2011 a cargo de diseñador Dalton Maag. ",
  },
  {
    itemTitle: "Logosímbolo y eslogan",
    itemDesc: "Como eslogan ocacional se usa la frase descriptiva de los servicios brindados, en este caso: Servicios Financieros. Para esta frase se utiliza la tipografía utilizada para el resto de los textos de la empresa, Ubuntu, y se coloca centrado con respecto al logosímbolo, y con un menor ancho para no ganar demasiado protagonismo.",
  },

  {
    itemTitle: "Variantes de color",
    itemSubtitle: "Positivo",
    itemDesc: "Versión de la marca gráfica sobre fondos claros con una sola tinta, utilización de colores corporativos o negro #000000 / Pantone Black 6 C.",
  },
  {
    itemTitle: "Variantes de color",
    itemSubtitle: "Negativo",
    itemDesc:"Versión de la marca gráfica sobre fondos oscuros con una sola tinta, utilización de colores corporativos o negro #000000 / Pantone Black 6 C, logo #ffffff / papel blanco.",
  },
]


export const dataQuote = [
  {
    Text: "El diseño no es solo lo que se ve y lo que se siente. El diseño es cómo funciona.",
    Autor: "Steve Jobs",
  }
]