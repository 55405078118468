import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import ReactFullpage from "@fullpage/react-fullpage"
import { FaChevronDown, FaQuoteRight } from "react-icons/fa"
import Img from "gatsby-image"
import BackgroundImage from "gatsby-background-image"
import Contact from "../../components/Contact"
import MainWorks from "../../components/MainWorks"
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from "react-accessible-accordion"
import {
  dataProcess,
  dataProcess2,
  dataItem,
  dataQuote,
} from "../../data/zerinthiaData"
import Ticker from "react-ticker"
import Slider from "react-slick"
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"

const Projectpage = ({ data }) => {
  const {
    allFile: { edges },
    allStrapiProjects: { nodes: projects },
    strapiProjects: {
      title,
      subtitle,
      description,
      mission,
      about,
      web,
      image,
      preview,
    },
  } = data

  const settingsSlider = {
    dots: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  }

  return (
    <ReactFullpage
      licenseKey={"YOUR_KEY_HERE"}
      scrollingSpeed={750}
      render={({ state, fullpageApi }) => {
        return (
          <>
            <header className="section hero section-center">
              <BackgroundImage
                fluid={edges[0].node.childImageSharp.fluid}
                className="hero-cover"
              >
                <div className="title-container text-center">
                  <h1>
                    <span className="text-black text-bold">{title}</span>
                    <span className="h1-subtitle">{subtitle}</span>
                  </h1>
                </div>

                <div
                  className="arrow arrow-down"
                  onClick={() => fullpageApi.moveSectionDown()}
                >
                  <FaChevronDown />
                </div>
              </BackgroundImage>
            </header>

            <div className="section mission">
              <div className="section-container">
                <h2>Misión del projecto</h2>
                <p>{description}</p>
                <ul>
                  <li>
                    <p>Cliente</p>
                    <p>{mission.cliente}</p>
                  </li>
                  <li>
                    <p>Localidad</p>
                    <p>{mission.localidad}</p>
                  </li>
                  <li>
                    <p>Industria</p>
                    <p>{mission.industria}</p>
                  </li>
                  <li>
                    <p>Fecha</p>
                    <p>{mission.fecha}</p>
                  </li>
                </ul>

                <a href={web} target="_blank" className="btn">
                  Ir al sitio
                </a>
              </div>
            </div>

            <div className="section company">
              <div className="section-container">
                <h3>Sobre la empresa</h3>
                <p>{about}</p>
                <Img fluid={edges[2].node.childImageSharp.fluid} />
              </div>
            </div>

            <div className="section process">
              <div className="section-container">
                <h2 className="portfolio-item">Proceso Marca</h2>
                <div className="process-text">
                  <Accordion>
                    {dataProcess.map(data => {
                      return (
                        <AccordionItem>
                          <AccordionItemHeading>
                            <AccordionItemButton>
                              <h5>{data.accHeader}</h5>
                            </AccordionItemButton>
                          </AccordionItemHeading>
                          <AccordionItemPanel>
                            <p>{data.accBody}</p>
                          </AccordionItemPanel>
                        </AccordionItem>
                      )
                    })}
                  </Accordion>
                </div>
                <div className="process-img">
                  <Img fluid={edges[3].node.childImageSharp.fluid} />
                </div>
              </div>
            </div>

            <div className="section">
              <Slider {...settingsSlider}>
                <div className="section process-item">
                  <div className="section-container">
                    <h2 className="portfolio-item">{dataItem[0].itemTitle}</h2>
                    <div className="process-text">
                      <p>{dataItem[0].itemDesc}</p>
                    </div>
                    <div className="process-img">
                      <Img
                        imgStyle={{ objectFit: "contain" }}
                        fluid={edges[4].node.childImageSharp.fluid}
                      />
                    </div>
                  </div>
                </div>

                <div className="section process-item">
                  <div className="section-container">
                    <h2 className="portfolio-item">{dataItem[1].itemTitle}</h2>
                    <div className="process-text">
                      <p>{dataItem[1].itemDesc}</p>
                    </div>
                    <div className="process-img">
                      <Img
                        imgStyle={{ objectFit: "contain" }}
                        fluid={edges[5].node.childImageSharp.fluid}
                      />
                    </div>
                  </div>
                </div>

                <div className="section process-item">
                  <div className="section-container">
                    <h2 className="portfolio-item">{dataItem[2].itemTitle}</h2>
                    <div className="process-text">
                      <p>{dataItem[2].itemDesc}</p>
                    </div>
                    <div className="process-img">
                      <Img
                        imgStyle={{ objectFit: "contain" }}
                        fluid={edges[6].node.childImageSharp.fluid}
                      />
                    </div>
                  </div>
                </div>

                <div className="section process-item">
                  <div className="section-container">
                    <h2 className="portfolio-item">{dataItem[3].itemTitle}</h2>
                    <div className="process-text">
                      <p>{dataItem[3].itemDesc}</p>
                    </div>
                    <div className="process-img">
                      <Img
                        imgStyle={{ objectFit: "contain" }}
                        fluid={edges[7].node.childImageSharp.fluid}
                      />
                    </div>
                  </div>
                </div>
              </Slider>
            </div>

            <div className="section">
              <BackgroundImage
                fluid={edges[1].node.childImageSharp.fluid}
                className="hero-cover"
              >
                <Ticker direction="toRight" speed={13}>
                  {() => <span>MARCA</span>}
                </Ticker>
                <Ticker direction="toLeft" speed={9}>
                  {() => <span>MARCA</span>}
                </Ticker>
                <Ticker direction="toRight" speed={11}>
                  {() => <span>MARCA</span>}
                </Ticker>
                <Ticker direction="toLeft" speed={8}>
                  {() => <h2>MARCA</h2>}
                </Ticker>
                <Ticker direction="toRight" speed={12}>
                  {() => <span>MARCA</span>}
                </Ticker>
                <Ticker direction="toLeft" speed={10}>
                  {() => <span>MARCA</span>}
                </Ticker>
                <Ticker direction="toRight" speed={14}>
                  {() => <span>MARCA</span>}
                </Ticker>
              </BackgroundImage>
            </div>

            <div className="section">
              <Slider {...settingsSlider}>
                <div className="section process-item">
                  <div className="section-container">
                    <h2 className="portfolio-item">{dataItem[4].itemTitle}</h2>
                    <div className="process-text">
                      <p>{dataItem[4].itemDesc}</p>
                    </div>
                    <div className="process-img">
                      <Img
                        imgStyle={{ objectFit: "contain" }}
                        fluid={edges[8].node.childImageSharp.fluid}
                      />
                    </div>
                  </div>
                </div>
                <div className="section process-item">
                  <div className="section-container">
                    <h2 className="portfolio-item">{dataItem[5].itemTitle}</h2>
                    <div className="process-text">
                      <p>{dataItem[5].itemDesc}</p>
                    </div>
                    <div className="process-img">
                      <Img
                        imgStyle={{ objectFit: "contain" }}
                        fluid={edges[9].node.childImageSharp.fluid}
                      />
                    </div>
                  </div>
                </div>
                <div className="section process-item">
                  <div className="section-container">
                    <h2 className="portfolio-item">{dataItem[6].itemTitle}</h2>
                    <div className="process-text">
                      <p>{dataItem[6].itemDesc}</p>
                    </div>
                    <div className="process-img">
                      <Img
                        imgStyle={{ objectFit: "contain" }}
                        fluid={edges[10].node.childImageSharp.fluid}
                      />
                    </div>
                  </div>
                </div>

                <div className="section process-item">
                  <div className="section-container">
                    <h2 className="portfolio-item">{dataItem[7].itemTitle}</h2>
                    <div className="process-text">
                      <p>{dataItem[7].itemDesc}</p>
                    </div>
                    <div className="process-img">
                      <Img
                        imgStyle={{ objectFit: "contain" }}
                        fluid={edges[11].node.childImageSharp.fluid}
                      />
                    </div>
                  </div>
                </div>

                <div className="section process-item">
                  <div className="section-container">
                    <h2 className="portfolio-item">{dataItem[8].itemTitle}</h2>
                    <div className="process-text">
                      <p>{dataItem[8].itemDesc}</p>
                    </div>
                    <div className="process-img">
                      <Img
                        imgStyle={{ objectFit: "contain" }}
                        fluid={edges[12].node.childImageSharp.fluid}
                      />
                    </div>
                  </div>
                </div>

                <div className="section process-item">
                  <div className="section-container">
                    <h2 className="portfolio-item">{dataItem[9].itemTitle}</h2>
                    <div className="process-text">
                      <p>{dataItem[9].itemDesc}</p>
                    </div>
                    <div className="process-img">
                      <Img
                        imgStyle={{ objectFit: "contain" }}
                        fluid={edges[13].node.childImageSharp.fluid}
                      />
                    </div>
                  </div>
                </div>

                <div className="section process-item">
                  <div className="section-container">
                    <h2 className="portfolio-item">{dataItem[10].itemTitle}</h2>
                    <div className="process-text">
                      <p>{dataItem[10].itemDesc}</p>
                    </div>
                    <div className="process-img">
                      <Img
                        imgStyle={{ objectFit: "contain" }}
                        fluid={edges[14].node.childImageSharp.fluid}
                      />
                    </div>
                  </div>
                </div>

                <div className="section process-item">
                  <div className="section-container">
                    <h2 className="portfolio-item">{dataItem[11].itemTitle}</h2>
                    <div className="process-text">
                      <p>{dataItem[11].itemDesc}</p>
                    </div>
                    <div className="process-img">
                      <Img
                        imgStyle={{ objectFit: "contain" }}
                        fluid={edges[15].node.childImageSharp.fluid}
                      />
                    </div>
                  </div>
                </div>
              </Slider>
            </div>

            <div className="section">
              <BackgroundImage
                fluid={edges[1].node.childImageSharp.fluid}
                className="hero-cover"
              >
                <Ticker direction="toRight" speed={13}>
                  {() => <span>PAPELERíA</span>}
                </Ticker>
                <Ticker direction="toLeft" speed={9}>
                  {() => <span>PAPELERíA</span>}
                </Ticker>
                <Ticker direction="toRight" speed={11}>
                  {() => <span>PAPELERíA</span>}
                </Ticker>
                <Ticker direction="toLeft" speed={8}>
                  {() => <h2>PAPELERíA</h2>}
                </Ticker>
                <Ticker direction="toRight" speed={12}>
                  {() => <span>PAPELERíA</span>}
                </Ticker>
                <Ticker direction="toLeft" speed={10}>
                  {() => <span>PAPELERíA</span>}
                </Ticker>
                <Ticker direction="toRight" speed={14}>
                  {() => <span>PAPELERíA</span>}
                </Ticker>
              </BackgroundImage>
            </div>

            <div className="section">
              <Slider {...settingsSlider}>
                <div className="section portfolio-image">
                  <div className="section-container">
                    <Img
                      imgStyle={{ objectFit: "contain" }}
                      fluid={edges[16].node.childImageSharp.fluid}
                    />
                  </div>
                </div>

                <div className="section portfolio-image">
                  <div className="section-container">
                    <Img
                      imgStyle={{ objectFit: "contain" }}
                      fluid={edges[17].node.childImageSharp.fluid}
                    />
                  </div>
                </div>

                <div className="section portfolio-image">
                  <div className="section-container">
                    <Img
                      imgStyle={{ objectFit: "contain" }}
                      fluid={edges[18].node.childImageSharp.fluid}
                    />
                  </div>
                </div>

                <div className="section portfolio-image">
                  <div className="section-container">
                    <Img
                      imgStyle={{ objectFit: "contain" }}
                      fluid={edges[19].node.childImageSharp.fluid}
                    />
                  </div>
                </div>

                <div className="section portfolio-image">
                  <div className="section-container">
                    <Img
                      imgStyle={{ objectFit: "contain" }}
                      fluid={edges[20].node.childImageSharp.fluid}
                    />
                  </div>
                </div>
              </Slider>
            </div>

            <div className="section process">
              <div className="section-container">
                <h2 className="portfolio-item">Proceso Web</h2>
                <div className="process-text">
                  <Accordion>
                    {dataProcess2.map(data => {
                      return (
                        <AccordionItem>
                          <AccordionItemHeading>
                            <AccordionItemButton>
                              <h5>{data.accHeader}</h5>
                            </AccordionItemButton>
                          </AccordionItemHeading>
                          <AccordionItemPanel>
                            <p>{data.accBody}</p>
                          </AccordionItemPanel>
                        </AccordionItem>
                      )
                    })}
                  </Accordion>
                </div>
                <div className="process-img">
                  <Img fluid={edges[3].node.childImageSharp.fluid} />
                </div>
              </div>
            </div>

            <div className="section">
              <Slider {...settingsSlider}>
                <div className="section portfolio-image">
                  <div className="section-container">
                    <Img
                      imgStyle={{ objectFit: "contain" }}
                      fluid={edges[21].node.childImageSharp.fluid}
                    />
                  </div>
                </div>
                <div className="section portfolio-image">
                  <div className="section-container">
                    <Img
                      imgStyle={{ objectFit: "contain" }}
                      fluid={edges[22].node.childImageSharp.fluid}
                    />
                  </div>
                </div>
                <div className="section portfolio-image">
                  <div className="section-container">
                    <Img
                      imgStyle={{ objectFit: "contain" }}
                      fluid={edges[23].node.childImageSharp.fluid}
                    />
                  </div>
                </div>
                <div className="section portfolio-image">
                  <div className="section-container">
                    <Img
                      imgStyle={{ objectFit: "contain" }}
                      fluid={edges[24].node.childImageSharp.fluid}
                    />
                  </div>
                </div>
                <div className="section portfolio-image">
                  <div className="section-container">
                    <Img
                      imgStyle={{ objectFit: "contain" }}
                      fluid={edges[25].node.childImageSharp.fluid}
                    />
                  </div>
                </div>
                <div className="section portfolio-image">
                  <div className="section-container">
                    <Img
                      imgStyle={{ objectFit: "contain" }}
                      fluid={edges[26].node.childImageSharp.fluid}
                    />
                  </div>
                </div>
                <div className="section portfolio-image">
                  <div className="section-container">
                    <Img
                      imgStyle={{ objectFit: "contain" }}
                      fluid={edges[27].node.childImageSharp.fluid}
                    />
                  </div>
                </div>
                <div className="section portfolio-image">
                  <div className="section-container">
                    <Img
                      imgStyle={{ objectFit: "contain" }}
                      fluid={edges[28].node.childImageSharp.fluid}
                    />
                  </div>
                </div>
                <div className="section portfolio-image">
                  <div className="section-container">
                    <Img
                      imgStyle={{ objectFit: "contain" }}
                      fluid={edges[29].node.childImageSharp.fluid}
                    />
                  </div>
                </div>
                <div className="section portfolio-image">
                  <div className="section-container">
                    <Img
                      imgStyle={{ objectFit: "contain" }}
                      fluid={edges[30].node.childImageSharp.fluid}
                    />
                  </div>
                </div>
                <div className="section portfolio-image">
                  <div className="section-container">
                    <Img
                      imgStyle={{ objectFit: "contain" }}
                      fluid={edges[31].node.childImageSharp.fluid}
                    />
                  </div>
                </div>
              </Slider>
            </div>

            <div className="section quote">
              <div className="section-container">
                <div className="quote-container">
                  <FaQuoteRight />
                  <h4>{dataQuote[0].Text}</h4>
                  <FaQuoteRight />
                  <p>{dataQuote[0].Autor}</p>
                </div>
              </div>
            </div>

            <MainWorks
              className="section other-works"
              projects={projects}
              title="Otros trabajos"
            />

            <Contact className="section" />

            <div
              className="arrow arrow-top"
              onClick={() => fullpageApi.moveTo(1)}
            >
              <FaChevronDown />
            </div>
          </>
        )
      }}
    />
  )
}

export const query = graphql`
  query {
    allFile(
      filter: {
        extension: { regex: "/(jpg)|(png)|(jpeg)/" }
        relativeDirectory: { eq: "portfolio/zerinthia" }
      }
      sort: { order: ASC, fields: name }
    ) {
      edges {
        node {
          base
          childImageSharp {
            fluid(maxWidth: 1920) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
    allStrapiProjects(
      sort: { order: DESC, fields: id }
      filter: { slug: { nin: "zerinthia" } }
      limit: 6
    ) {
      nodes {
        id
        description
        slug
        title
        subtitle
        preview {
          childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid
            }
          }
        }
        stack {
          id
          title
        }
      }
    }
    strapiProjects(slug: { eq: "zerinthia" }) {
      title
      subtitle
      description
      web
      mission {
        cliente
        localidad
        industria
        fecha
      }
      about
      image {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
      preview {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  }
`

export default Projectpage
